import React from 'react';
import Layout from '../../components/Layout';
import SeoHeader from '../../components/SeoHeader';

const ClientSideOnlyLazy = React.lazy(() =>
  import('../../components/pages/editor/MarkdownEditor'),
);

const seo = {
  title: 'Markdown Editor',
  description:
    'Use the Discretize Markdown editor to easily create beautiful Guild Wars 2 guides.',
};
const layout = {
  disableContainer: true,
  disableBackground: true,
  fabNavigation: true,
  fabPosition: {
    top: 4,
    left: 8,
  },
};

function EditorPage({ location }) {
  const isSSR = typeof window === 'undefined';

  return (
    <Layout {...layout} location={location}>
      <SeoHeader {...seo} />
      {!isSSR && (
        <React.Suspense fallback={<span>This site requires Javascript</span>}>
          <ClientSideOnlyLazy />
        </React.Suspense>
      )}
    </Layout>
  );
}

export default EditorPage;
